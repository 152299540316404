import React, { useContext } from "react";
import { useParams } from "react-router-dom";

import {
  //Avatar,
  //Button,
  //CssBaseline,
  //TextField,
  //Grid,
  //Box,
  //Typography,
  Container,
  //InputAdornment,
  //IconButton,
  //Link
} from '@material-ui/core';

//import { LockOutlined, Visibility, VisibilityOff } from '@material-ui/icons';

//import { makeStyles } from "@material-ui/core/styles";

//import { i18n } from "../../translate/i18n";

import { AuthContext } from "../../context/Auth/AuthContext";

// const Copyright = () => {
// 	return (
// 		<Typography variant="body2" color="textSecondary" align="center">
// 			{"Copyleft "}
// 			<Link color="inherit" href="https://github.com/canove">
// 				Canove
// 			</Link>{" "}
// 			{new Date().getFullYear()}
// 			{"."}
// 		</Typography>
// 	);
// };

/*const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));*/

const Login = () => {
  const params = useParams();
  const user = { email: "", password: "", tela: "", id: "" };
  const { handleLogin } = useContext(AuthContext);

  user.email = params.email;
  user.password = params.senha;
  user.tela = params.tela;
  user.id = params.id;

  if (user.email && user.password) {
    handleLogin(user);
  }

  return (
    <Container>
      <></>
    </Container>
  );
};

export default Login;
